<template>
  <div class="supportInfo" v-if="info">
    <div class="supportCotent">
      <van-row>
        <van-col span="8">{{ $t.support.serviceType }}:</van-col>
        <van-col span="12">{{ info.ServiceTypeName }}</van-col>
      </van-row>
      <van-row>
        <van-col span="8">{{ $t.support.serviceTitle }}:</van-col>
        <van-col span="12">{{ info.Title }}</van-col>
      </van-row>
      <van-row>
        <van-col span="8">{{ $t.support.serviceStatus }}:</van-col>
        <van-col span="12" :class="[info.Status == 1 ? 'redTxt' : '']">{{
          info.StatusStr
        }}</van-col>
      </van-row>
      <van-row>
        <van-col span="8">{{ $t.support.serviceDes }}:</van-col>
        <van-col span="12">{{ info.Content }}</van-col>
      </van-row>
      <van-row>
        <van-col span="8">{{ $t.support.serviceContact }}:</van-col>
        <van-col span="12">{{ info.ContactWay }}</van-col>
      </van-row>
      <van-row>
        <van-col span="8">{{ $t.support.serviceTime }}:</van-col>
        <van-col span="12">{{ info.CreateTime }}</van-col>
      </van-row>
    </div>
    <div class="supportList" v-for="(item, index) in info.prList" :key="index">
      <van-row>
        <van-col span="8">{{ $t.support.servicePosted }}:</van-col>
        <van-col span="12">{{ item.UserName }}</van-col>
      </van-row>
      <van-row>
        <van-col span="8">{{ $t.support.serviceContent }}:</van-col>
        <van-col span="12">{{ item.ReplyContent }}</van-col>
      </van-row>
      <van-row>
        <van-col span="8">{{ $t.support.serviceReplyTime }}:</van-col>
        <van-col span="12">{{ item.ReplyTime }}</van-col>
      </van-row>
    </div>
    <div class="supportBox">
      <section class="itemBox newItemBox">
        <span>{{ $t.support.serviceReplyContent }}</span>
      </section>
      <section class="itemBox">
        <textarea
          class="address"
          v-model="reply.replyText"
          rows="5"
          :placeholder="$t.support.serviceInputReplyContent"
        ></textarea>
      </section>
    </div>

    <div class="submitBox" @click="submit">
      <div class="submit">{{ $t.meta.submit }}</div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Row, Col } from "vant";
import Schema from "async-validator";

Vue.use(Row).use(Col);
export default {
  name: "SupportDetail",
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      userId: "",
      info: {},
      type: "C",
      reply: {
        replyText: "",
      },
    };
  },
  mounted() {
    this.userId = JSON.parse(localStorage.getItem("auth")).userId;
    this.getMessageInfo();
  },
  methods: {
    submit() {
      const rule = {
        replyText: {
          required: true,
          message: this.$t.support.serviceInputReplyContent,
        },
      };
      const validator = new Schema(rule);
      validator
        .validate(this.reply)
        .then(() => {
          this.$commonMethod.showLoading();
          let param = {
            PFId: this.id,
            userId: this.userId,
            content: this.reply.replyText,
          };
          this.$api.system
            .addSupportReply(param)
            .then((res) => {
              this.$commonMethod.hideLoading();
              this.getMessageInfo();
            })
            .catch((error) => {
              this.$commonMethod.hideLoading();
              this.$commonEnv.commonAction(
                this.$commonEnv.actionType.showDialog,
                {
                  msg: error.message,
                  status: error.status,
                }
              );
            });
        })
        .catch((err) => {
          if (err.errors) {
            this.$commonMethod.showNotify({
              type: "danger",
              titleMsg: err.errors[0].message,
            });
          }
        });
    },
    getMessageInfo() {
      this.$commonMethod.showLoading();
      let param = {
        PFId: this.id,
        userId: this.userId,
        ReceipType: this.type,
      };
      this.$api.system
        .getSupportInfo(param)
        .then((res) => {
          const data = res.data;
          this.info = data;
          this.$commonMethod.hideLoading();
        })
        .catch((error) => {
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin newBox1 {
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 10px;
}
@mixin boxLeft {
  max-width: 35%;
  text-align: left;
  color: #000000;
}
@mixin boxright {
  max-width: 65%;
  text-align: right;
  font-size: 14px;
  color: #000000;
  font-weight: bold;
  background: none;
  outline: none;
  border: none;
}
.supportInfo {
  .supportCotent {
    background: #fff;
    padding: 3%;
    border-bottom: 1px solid #e5e5e5;
    .van-row {
      padding: 1% 3% 1% 3%;
      .van-col:first-child {
        color: #999;
      }
      .van-col {
        line-height: 23px;
      }
      .redTxt {
        color: red;
      }
    }
  }
  .supportList {
    margin-top: 3%;
    background: #fff;
    padding: 3%;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    .van-row {
      padding: 1% 3% 1% 3%;
      .van-col:first-child {
        color: #999;
      }
      .van-col {
        line-height: 23px;
      }
    }
  }
  .supportBox {
    @include newBox1;
    .itemBox {
      justify-content: space-between;
      -webkit-justify-content: space-between;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      padding: 10px;
      @include publicFlex;
      .itemBoxLeft {
        @include boxLeft;
      }
      .itemBoxRight {
        @include boxright;
      }
      .address {
        width: 100%;
        border: none;
      }
      .itemSelect {
        .selectText {
          display: inline-block;
        }
        .icon {
          width: 12px;
          height: 12px;
          margin-left: 10px;
          flex-shrink: 0;
        }
      }
    }
    .newItemBox {
      border-bottom: 1px solid #f6f6f6;
      color: #333;
    }
  }
  .submitBox {
    margin: 5% auto;
    width: 92%;
    .submit {
      position: relative;
      width: 100%;
      margin: 0 auto;
      border-radius: 3rem;
      padding: 0.8rem 0;
      text-align: center;
      background: linear-gradient(left, #71d283, #01aaa3);
      color: #ffffff;
      font-size: 1rem;
      line-height: 1;
    }
  }
}
</style>